<template>
	<!-- :style="`backgroundImage: url(${info.background})`" -->
	<div class="page">
		<div class="bgbox" :class="lang==6?'':'bgboxEn'">
			<img src="../../assets/brandValueBg.png" class="bg" />
			<p>{{lang==6?'尊重':'Respect'}}</p>
			<p>{{lang==6?'客户至上':'Customer Orientation'}}</p>
			<p v-if="lang==6">拥抱改变<br>不断成长</p>
			<p v-else>Enjoy Change<br>&<br>Growth</p>
			<p>{{lang==6?'团队精神':'Team Spirit'}}</p>
			<p v-if="lang==6">责任与信任</p>
			<p v-else>Responsibility<br>&<br>Trust</p>
			<p v-if="lang==6">宝爱捷（中国）<br>核心价值</p>
			<p v-else>PAIG (China)<br>Group Core Value</p>
		</div>

		<div class="content" :class="lang==6? '': 'contentEN'">
			<div class="line" id="content">
				<div class="left">
					<img src="../../assets/brandValueicon1.png" />
					<div>
						<div v-if="lang==6">客户至上</div>
						<div v-else>Customer <br> Orientation</div>
						<div class="yellowLine"></div>
					</div>
				</div>
				<div class="right">
					<p v-if="lang==6">
						我们因客户、为客户而生，所以我们要成为客户心中首选和心中最佳。我们所做的每件事，都必须聚焦在客户身上。保时捷控股在国际汽车行业屹立70余年，始终坚持客户至上的初衷从未改变。</p>
					<p v-else>We live by and for our customers' success, we want to be their top-of-mind and
						top-of-heart choice. <br>
						In everything we do, the focus is always on the customer. Standing for more than 70 years
						international automotive experience of Porsche Holding, this initial intention-CUSTOMER
						ORIENTATION has never been changed.</p>
				</div>
			</div>

			<div class="line">
				<div class="left">
					<img src="../../assets/brandValueicon2.png" />
					<div>
						<div>{{lang==6?'尊重':'Respect'}}</div>
						<div class="yellowLine"></div>
					</div>
				</div>
				<div class="right">
					<p v-if="lang==6">我们把员工视为我们最宝贵的财富，我们关注员工发展，并为此投资。我们认可个体差异并互相关爱。我们发自内心地理解客户的需求、满足客户的期待。</p>
					<p v-else>We profess that people are our greatest asset, and we do make efforts to develop people
						and invest in people in visible ways. <br>
						Recognizing each other's differences, we show consideration for each other. We understand the
						customer's needs and expectations and go out of our way to meet these.
					</p>
				</div>
			</div>

			<div class="line">
				<div class="left">
					<img src="../../assets/brandValueicon3.png" />
					<div>
						<div v-if="lang==6">责任和信任</div>
						<div v-else>Responsibility & <br> Trust</div>
						<div class="yellowLine"></div>
					</div>
				</div>
				<div class="right">
					<p v-if="lang==6">我们对自己的选择负责，能意识到自己的言行将影响周围的人。在达成个人和团队目标过程中遇到困难和挫折，我们决不轻言放弃。我们值得信赖。</p>
					<p v-else>
						Be conscious: we are aware and careful of the choice we make, and accept that our actions impact
						everyone around us. <br>
						Be persevering: we work through difficulty and discouragement in pursuit of individual and team
						objectives. <br>
						Be reliable: we prove that others can depend on us to do the right thing.
					</p>
				</div>
			</div>

			<div class="line">
				<div class="left">
					<img src="../../assets/brandValueicon4.png" />
					<div>
						<div v-if="lang==6">拥抱改变，不断成长</div>
						<div v-else>Enjoy Change & <br> Growth</div>
						<div class="yellowLine"></div>
					</div>
				</div>
				<div class="right">
					<p v-if="lang==6">
						我们乐于接受改变，促成每天的进步。无论遇到什么问题、身处什么环境，每个人都要用创新思维应对，成为创新者。我们相信智慧、理性和科技能促进业务的发展。我们敢于承担风险，热烈欢迎新的创意。只有拥抱改变才能持续发展。
					</p>
					<p v-else>
						We love grand challenges, as well as a day to day improvement. Whatever the problem or the
						context, every one seeks ways to tackle it creatively-to be an innovator. We believe that the
						application of intelligence, reason and science can improve business. <br>
						We take informed risks and champion new ideas. Only enjoying change can make the sustainable
						growth possible.
					</p>
				</div>
			</div>

			<div class="line">
				<div class="left">
					<img src="../../assets/brandValueicon5.png" />
					<div>
						<div>{{lang==6?'团队精神':'Team Spirit'}}</div>
						<div class="yellowLine"></div>
					</div>
				</div>
				<div class="right">
					<p v-if="lang==6">
						一个人很渺小，
						但是我们携手一起的时候就强大无比。我们一起工作，分享好的创意和成功的经验。我们在生活上和事业上互相照顾一起成长。我们在工作中积极动脑，乐于我们所从事的事业。作为团队成员，无论出现任何挑战，我们都勇于承担、积极合作。我们之间通过正能量互相影响，共同创造和谐共存、荣辱与共的氛围。
					</p>
					<p v-else>
						A single one person is small and weak, but with each one together, we become strong. We work
						together as one team. We share ideas and best practices. We help each other to grow personally
						and professionally. We work smart and have fun in what we do. <br>
						As team members, we take ownership of issues and collaborate with others whenever challenges
						arise. Each of us has a positive influence on each other. The whole team focuses on creating
						harmony with each other and whoever else we interact with.
					</p>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	import mixinLang from '../../components/mixin.js'
	export default {
		mixins: [mixinLang],
		props: {

		},
		data() {
			return {
				info: {}
			}
		},
		mounted() {
			if (this.$route.query.id) {
				document.querySelector('#' + this.$route.query.id).scrollIntoView(true)
				console.log(1)
			}
		},
		methods: {
			getInfo() {
				this.$api.getConfigure({
					type: 9
				}).then(res => {
					this.info = res.data
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding-top: 0px;
		// padding-bottom: 80px;
	}

	.bgbox {
		.bg {
			width: 100%
		}

		p {
			position: absolute;
			width: 250px;
			height: 250px;
			background-color: rgba(181, 161, 145, .5);
			font-family: Montserrat-Bold, sans-serif;
			font-size: 32px;
			font-weight: bold;
			border-radius: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #FFF;
			text-align: center;
			line-height: normal;

			&:nth-child(2) {
				left: 747px;
				top: 221px;
				animation: bubble 5.5s linear .8s infinite;
			}

			&:nth-child(3) {
				left: 1305px;
				top: 301px;
				animation: bubble 6s linear .8s infinite;
			}

			&:nth-child(4) {
				left: 1369px;
				top: 756px;
				animation: bubble 5s linear .5s infinite;
			}

			&:nth-child(5) {
				left: 535px;
				top: 814px;
				animation: bubble 6s linear 1s infinite;
			}

			&:nth-child(6) {
				left: 235px;
				top: 420px;
				animation: bubble 5s linear infinite;
			}

			&:nth-child(7) {
				width: 330px;
				height: 330px;
				background: #97233F;
				font-size: 40px;
				left: 810px;
				top: 546px;
				animation: bigbubble 5s linear infinite;
			}
		}

	}

	.bgboxEn {
		p {
			width: 300px;
			height: 300px;

			&:nth-child(2) {
				left: 747px;
				top: 161px;
				animation: bubble 4s linear .8s infinite;
			}

			&:nth-child(7) {
				width: 400px;
				height: 400px;
			}
		}
	}

	@keyframes bigbubble {
		0% {
			transform: scale(1) translateY(0);
			opacity: 1;
		}

		50% {
			transform: scale(.9) translateY(-50px);
			opacity: 0.7;
		}

		100% {
			transform: scale(1) translateY(0px);
			opacity: 1;
		}
	}

	@keyframes bubble {
		0% {
			transform: scale(1) translateY(0);
			opacity: 1;
		}

		50% {
			transform: scale(.7) translateY(150px);
			opacity: 0.5;
		}

		100% {
			transform: scale(1) translateY(0px);
			opacity: 1;
		}
	}

	.content {
		width: 1600px;
		margin: 0 auto;
		border-radius: 32px;
		// background: linear-gradient(270deg, rgba(32, 41, 56, 0.80) 0%, rgba(32, 41, 56, 0.00) 100%);
		// box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
		background: linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 100%);
		box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
		padding: 96px 99px;
		position: relative;
		transform: translateY(-290px);

		&::after {
			content: '';
			height: calc(100% - (96px*2));
			width: 2px;
			position: absolute;
			display: block;
			left: 480px;
			top: 96px;
			background-color: #97233F;
		}

		.line {
			display: flex;
			margin-bottom: 70px;
			justify-content: space-between;

			.left {
				width: 350px;
				font-family: SourceHanSansCN-Bold, sans-serif;
				font-size: 32px;
				font-weight: bold;
				color: #97233F;
				display: flex;

				.yellowLine {
					margin-top: 20px;
				}

				img {
					width: 44px;
					height: 44px;
					margin-right: 16px;
					// width: 100%;
				}
			}

			.right {
				width: 880px;
				font-family: SourceHanSansCN-Normal, sans-serif;
				font-size: 22px;
				font-weight: 350;
				line-height: 44px;
				color: #333333;
			}
		}
	}

	.contentEN {
		/* &::after {
			left: 550px;
		}

		.left {
			width: 450px !important;
		} */
	}
</style>